import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./styles.css";

const TrenMayaAdvertising = ({ data }) => {
    return (<>
        <div class="container text-center">
            <div className="row">
                <h2 className="informacion--titulo">{data.advTitle}</h2>
            </div>
            <p className="informacion--texto">Si estás en la <b style={{color: "#fda700"}}>Ciudad de México</b> visita el punto de venta físico, ubicado en la Secretaría de Turismo.</p>
            <div class="row">
                <div class="col">
                    
                </div>
                <div class="col">
                    <LazyLoadImage
                        src={require(`../../assets/img/${data.advImgUrl}`)}
                        alt=""
                        className="informacion--imagenAdv"
                    />
                </div>
                <div class="col">

                </div>
            </div>
            <div className="row">
                <h3 className="informacion--subtitulo">{data.advPayment}</h3>
            </div>
        </div>
    </>
    );
};

export default TrenMayaAdvertising;