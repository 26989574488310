export const infoJp = [
  {
    image: "tren-maya-cover.jpg",
    title: "Tren Maya",
    description: [
      {
        text: "Tren Maya",
      },
    ],
    urlTitle: "ビデオ",
    itemType: "video",
    url: "https://www.youtube.com/embed/OzJNdv4iJe0?rel=0&rel=0&autoplay=1&mute=0&loop=1",
  },

  {
    image: "mexicana.jpg",
    title: "Mexicana",
    description: [
      {
        text: "",
      },
    ],
    urlTitle: "ビデオ",
    itemType: "video",
    url: "https://www.youtube.com/embed/7abJsfETuZE?rel=0&rel=0&autoplay=1&mute=0&loop=1",
  },

  {
    image: "homeSlide1.jpg",
    title: "México através de los años",
    description: [
      {
        text: "メキシコの歴史を知ろう！",
      },
      {
        text: "もっと見る",
      },
    ],
    urlTitle: "ビデオ",
    itemType: "video",
    url: "https://www.youtube.com/embed/21cDYP2C9R4?rel=0&autoplay=1&mute=0&loop=1",
  },
  {
    image: "homeSlide2.jpg",
    title: "Descubre sitios arquelógicos",
    description: [
      {
        text: "メキシコの歴史を語る遺跡スポット",
      },
      {
        text: "もっと見る",
      },
    ],
    urlTitle: "ビデオ",
    itemType: "video",
    url: "https://www.youtube.com/embed/vyj7S5IoX1Q?rel=0&autoplay=1&mute=0&loop=1",
  },

  {
    image: "homeSlide3.jpg",
    title: "Nuevos nombramientos, pueblos mágicos",
    description: [
      {
        text: "プエブロ・マヒコ",
      },
      {
        text: "Pueblos Mágicos 2023",
      },
    ],
    urlTitle: "もっと見る",
    itemType: "enlace",
    url: "/pueblos-magicos",
  },
  {
    image: "homeSlide4.jpg",
    title: "Explora los Barrios Mágicos:",
    description: [
      {
        text: "バリオ・マヒコ巡り(新規登録)",
      },
      {
        text: "",
      },
    ],
    urlTitle: "もっと見る",
    itemType: "enlace",
    url: "/barrios-magicos",
  },
];
