import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../Context";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Logo from "../../assets/img/header/logo.png";
import "./styles.css";

import footerEs from "../../assets/data/footer/es/index.json";
import footerEn from "../../assets/data/footer/en/index.json";
import footerFr from "../../assets/data/footer/fr/index.json";
import footerDe from "../../assets/data/footer/de/index.json";
import footerPt from "../../assets/data/footer/pt/index.json";
import footerIt from "../../assets/data/footer/it/index.json";
import footerJp from "../../assets/data/footer/jp/index.json";
import footerCn from "../../assets/data/footer/cn/index.json";

const Footer = () => {
  const { isLanguage } = useContext(Context);
  const [language, setLanguage] = useState(footerEs);

  useEffect(() => {
    isLanguage === "ES"
      ? setLanguage(footerEs)
      : isLanguage === "EN"
      ? setLanguage(footerEn)
      : isLanguage === "FR"
      ? setLanguage(footerFr)
      : isLanguage === "DE"
      ? setLanguage(footerDe)
      : isLanguage === "PT"
      ? setLanguage(footerPt)
      : isLanguage === "IT"
      ? setLanguage(footerIt)
      : isLanguage === "JP"
      ? setLanguage(footerJp)
      : isLanguage === "CN"
      ? setLanguage(footerCn)
      : setLanguage(footerEs);
  }, [isLanguage]);

  // Dentro del componente Footer, reemplaza la sección estática de redes sociales con lo siguiente:

  const socialMediaLinks = () => (
    <div>
      <p className="footer-contact-section-title">{language?.followUs}</p>
      <div>
        {language?.socialMedia?.map((social) => (
          <a
            key={social.name}
            href={social.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="footer-contact-rss"
              src={require(`../../assets/img/${social.iconSrc}`)}
              alt={social.alt}
            />
          </a>
        ))}
      </div>
    </div>
  );

  const linksFast = () =>
    language?.linksFast?.links?.map((item) => (
      <li
        key={item?.name}
        className={
          item.type === "image"
            ? "footer-image-item"
            : item.type === "button"
            ? "footer-button-item"
            : ""
        }
      >
        {item.type === "image" ? (
          <a href={item?.dir} target="_blank" rel="noopener noreferrer">
            <img
              src={require(`../../assets/img/${item?.imageSrc}`)}
              alt={item?.alt}
              className="footer-image"
            />
          </a>
        ) : item.type === "button" ? (
          <a
            href={item?.dir}
            target="_blank"
            rel="noopener noreferrer"
            className="footer-button"
          >
            {item?.name}
            <img
              src={require(`../../assets/img/${item?.iconSrc}`)}
              alt={item?.alt}
              className="footer-button-icon"
            />
          </a>
        ) : (
          <Link to={item?.dir} className="footer-contact-section-text">
            {item?.name}
          </Link>
        )}
      </li>
    ));

  const linksKnow = () =>
    language?.linksKnow?.links?.map((item) => (
      <li
        key={item?.name}
        className={item.type === "image" ? "footer-image-item" : ""}
      >
        {item.type === "image" ? (
          <a href={item?.dir} target="_blank" rel="noopener noreferrer">
            <img
              src={require(`../../assets/img/${item?.imageSrc}`)}
              alt={item?.alt}
              className="footer-image"
            />
          </a>
        ) : (
          <Link to={item?.dir} className="footer-contact-section-text">
            {item?.name}
          </Link>
        )}
      </li>
    ));

  const linksContact = () =>
    language?.linksContact?.links?.map((item) => (
      <li key={item?.name}>
        <Link to={item?.dir} className="footer-contact-section-text">
          {item?.name}
        </Link>
      </li>
    ));

  const sections = () => (
    <>
      <div className="footer-ctn-contact-section">
        <p className="footer-contact-section-title">
          {language?.linksFast?.name}
        </p>
        <ul className="footer-contact-section-ul">{linksFast()}</ul>
      </div>
      <div className="footer-ctn-contact-section">
        <p className="footer-contact-section-title">
          {language?.linksKnow?.name}
        </p>
        <ul className="footer-contact-section-ul">{linksKnow()}</ul>
      </div>
      <div className="footer-ctn-contact-section">
        <p className="footer-contact-section-title">
          {language?.linksContact?.name}
        </p>
        <ul className="footer-contact-section-ul">{linksContact()}</ul>
      </div>
    </>
  );

  return (
    <div className="footer-ctn">
      <div className="footer-ctn-contact">
        <div className="footer-ctn-contact-section">
          <Link to={"/"}>
            <LazyLoadImage
              src={Logo}
              alt="visitmexico"
              className="footer-contact-logo"
            />
          </Link>
          <p className="footer-contact-section-text">{language?.description}</p>
          {/* Reemplaza el contenido estático aquí */}
          {socialMediaLinks()}
        </div>
        {sections()}
      </div>
      <div className="footer-ctn-contact-legal">
        <div className="footer-ctn-contact-legal-links">
          <Link className="footer-contact-legal-link" to="/">
            {language?.legalWarning}
          </Link>
          <Link className="footer-contact-legal-link" to="/">
            {language?.privacyPolicy}
          </Link>
        </div>
        <div className="footer-ctn-contact-legal-links">
          <p className="footer-contact-legal-link">{language?.register}</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
