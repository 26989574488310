export const infoFr = [
  {
    image: "tren-maya-cover.jpg",
    title: "Tren Maya",
    description: [
      {
        text: "Tren Maya",
      },
    ],
    urlTitle: "Voir plus",
    itemType: "video",
    url: "https://www.youtube.com/embed/OzJNdv4iJe0?rel=0&rel=0&autoplay=1&mute=0&loop=1",
  },

  {
    image: "mexicana.jpg",
    title: "Mexicana",
    description: [
      {
        text: "",
      },
    ],
    urlTitle: "Voir plus",
    itemType: "video",
    url: "https://www.youtube.com/embed/7abJsfETuZE?rel=0&rel=0&autoplay=1&mute=0&loop=1",
  },

  {
    image: "homeSlide1.jpg",
    title: "México através de los años",
    description: [
      {
        text: "Le Mexique à travers les années. ",
      },
      {
        text: "Admirez-le !",
      },
    ],
    urlTitle: "Voir plus",
    itemType: "video",
    url: "https://www.youtube.com/embed/21cDYP2C9R4?rel=0&autoplay=1&mute=0&loop=1",
  },
  {
    image: "homeSlide2.jpg",
    title: "Descubre sitios arquelógicos",
    description: [
      {
        text: "Découvrez les sites archéologiques ",
      },
      {
        text: "qui préservent le passé du Mexique",
      },
    ],
    urlTitle: "Voir plus",
    itemType: "video",
    url: "https://www.youtube.com/embed/yXzfHOB5S6g?rel=0&autoplay=1&mute=0&loop=1",
  },

  {
    image: "homeSlide3.jpg",
    title: "Nuevos nombramientos, pueblos mágicos",
    description: [
      {
        text: "Pueblos Mágicos 2023: ",
      },
      {
        text: "nouveaux nominations",
      },
    ],
    urlTitle: "Voir plus",
    itemType: "enlace",
    url: "/pueblos-magicos",
  },
  {
    image: "homeSlide4.jpg",
    title: "Explora los Barrios Mágicos:",
    description: [
      {
        text: "Explorez les Barrios Mágicos:",
      },
      {
        text: "Nouvelle désignation",
      },
    ],
    urlTitle: "Voir plus",
    itemType: "enlace",
    url: "/barrios-magicos",
  },
];
