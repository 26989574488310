import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../Context';
import homeEs from '../../assets/data/homeSlider/es/index.json';
import homeEn from '../../assets/data/homeSlider/en/index.json';

import HomeLoading from '../../components/homeLoading';
import Carousel from '../../components/carousel';
// import HomeSlider from '../../components/homeSlider';
// import HomeFinder from '../../components/homeFinder';
import HomeKnow from '../../components/homeKnow';
import HomeTheMostViewed from '../../components/homeTheMostViewed';
import HomeSunBeachAndSand from '../../components/homeSunBeachAndSand';
import HomeTravelInspiration from '../../components/homeTravelInspiration';
import HomeSliderVideoCards from '../../components/homeSliderVideoCards';
// import HomeExperience360 from '../../components/homeExperience360';
import HomeRecentArticles from '../../components/homeRecentArticles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import MetaTags from '../../components/metaTags';

const Home = () => {
  const { isLanguage } = useContext(Context);
  const [language, setLanguage] = useState(homeEs);

  useEffect(() => {
    isLanguage === 'ES' ? setLanguage(homeEs)
    : isLanguage === 'EN' ? setLanguage(homeEn)
    : setLanguage(homeEs)
  }, [isLanguage]);

  const [loading, setLoading] = useState(false);

  window?.scroll({
    top: 0
  });

  useEffect(() => {
    setTimeout(() => {
      setLoading(!loading);
    }, 3000);
  }, []);

  const metaInfo = {
    'ES': {
      title: 'México: Destinos Turísticos y Experiencias Inolvidables',
      description: 'Descubre los mejores destinos turísticos, culturas y experiencias que México tiene para ofrecer. Planifica tu viaje con Visit México.'
    },
    'EN': {
      title: 'Mexico: Tourist Destinations & Unforgettable Experiences',
      description: 'Discover top tourist destinations, cultures, and experiences Mexico offers. Plan your trip with Visit Mexico.'
    },
    'FR': {
      title: 'Mexique: Destinations Touristiques & Expériences Inoubliables',
      description: 'Découvrez les meilleures destinations touristiques, cultures et expériences que le Mexique a à offrir. Planifiez votre voyage avec Visit Mexico.'
    },
    'DE': {
      title: 'Mexiko: Touristische Ziele & Unvergessliche Erlebnisse',
      description: 'Entdecken Sie die besten Reiseziele, Kulturen und Erlebnisse, die Mexiko zu bieten hat. Planen Sie Ihre Reise mit Visit Mexico.'
    },
    'PT': {
      title: 'México: Destinos Turísticos e Experiências Inesquecíveis',
      description: 'Descubra os melhores destinos turísticos, culturas e experiências que o México tem a oferecer. Planeje sua viagem com Visit Mexico.'
    },
    'IT': {
      title: 'Messico: Destinazioni Turistiche & Esperienze Indimenticabili',
      description: 'Scopri le migliori destinazioni turistiche, culture ed esperienze che il Messico ha da offrire. Pianifica il tuo viaggio con Visit Mexico.'
    },
    'JP': {
      title: 'メキシコ: 観光地と忘れられない体験',
      description: 'メキシコが提供する最高の観光地、文化、体験を発見してください。Visit Mexicoで旅行を計画しましょう。'
    },
    'CN': {
      title: '墨西哥：旅游胜地和难忘的经历',
      description: '探索墨西哥提供的最佳旅游胜地、文化和体验。与Visit Mexico一起计划您的旅行。'
    }
  };

  return (
    <>
      <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
      {loading ?
        <>
          {/*<HomeSlider data={language} />*/}
          <Carousel data={language} />

          {/*<HomeFinder data={language}/>*/}
          <HomeKnow data={language} />
          <HomeTheMostViewed data={language} />
          <HomeSunBeachAndSand data={language} />
          <HomeTravelInspiration data={language} />
          <HomeSliderVideoCards data={language} />
          {/* <HomeExperience360 /> */}
          {/* fragmento temporal de codigo, se debe pasar a un compente generico */}
          <div className="magic-towns-description-ctn">
            <div className="magic-towns-description-ctn-description">
              <div className="magic-towns-description-ctn-img">
                <Link to='https://www.mexico360.com.mx/'>
                  <LazyLoadImage
                    src={require(`../../assets/img/header/videos360.png`)}
                    className="magic-towns-description-img"
                  />
                </Link>

              </div>
            </div>
          </div>
          <HomeRecentArticles data={language} />
        </>
        :
        <>
          <HomeLoading />
        </>
      }
    </>
  );
};

export default Home;
