import React from 'react';
import './styles.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const TrenMayaBanner = ({ data }) => {
  return (<>
    {/* <LazyLoadImage src="https://www.mexicana.gob.mx/assets/images/landing_page/inicio/movil/rentacar_abc7fca9.webp" alt="https://www.mexicna.gob.mx/assets/images/landing_page/inicio/movil/rentacar_abc7fca9.webp" class="shrinkToFit" width="100%"/> */}
    <section className="train-maya-video-top-ctn">
      <iframe
        width="100%"
        height="100%"
        src={data?.urlVideo}
        title={data?.title}
        frameBorder="1"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        loading='lazy'
      />
    </section>
  </>
  )
};

export default TrenMayaBanner;
